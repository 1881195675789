// https://stackoverflow.com/questions/61885716/uncaught-error-vue-composition-api-must-call-vue-useplugin-before-using-any
import "./installCompositionApi.js";
import Vue from "vue";

import store from "./store/";
import router from "./router";

import hooks, { useState } from "@u3u/vue-hooks";
Vue.use(hooks);

let ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT;

// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

// if (ENVIRONMENT == 'production') {
//     Sentry.init({
//         Vue,
//         dsn: "https://c4b0decdc13c42ed91c42fda9f84c589@o427321.ingest.sentry.io/5371203",
//         integrations: [
//             new Integrations.BrowserTracing({
//                 routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                 // tracingOrigins: ["localhost", "example.com:8080", "test.e-sian.com", "test.e-vino.com", "app.e-sian.com", "app.e-vino.com", "www.e-sian.com", "www.e-vino.com", /^\//],
//                 tracingOrigins: ["localhost", "test.e-sian.com", "test.e-vino.com", "app.e-sian.com", "app.e-vino.com", "www.e-sian.com", "www.e-vino.com", /^\//],
//             }),
//         ],
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: ENVIRONMENT == 'dev' ? 1.0 : 0.2,
//         logErrors: true,
//         environment: ENVIRONMENT,
//     });
// }

/*
 *********************************************************************************
 * fontawesome
 * https://fontawesome.com/how-to-use/on-the-web/using-with/vuejs
 *********************************************************************************
 */
import { library } from "@fortawesome/fontawesome-svg-core";

import {
    faCode,
    faArrowCircleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBars,
    faBell,
    faBox,
    faCalendarAlt,
    faCashRegister,
    faCheck,
    faCheckCircle,
    faCircle,
    faClipboard,
    faClone,
    faCog,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faFileAlt,
    faFileDownload,
    faFileImport,
    faFingerprint,
    faHistory,
    faInfoCircle,
    faKey,
    faList,
    faLink,
    faLock,
    faMinus,
    faMinusSquare,
    faMoneyBillWaveAlt,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPrint,
    faQuestionCircle,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSquare,
    faSync,
    faSyncAlt,
    faTimes,
    faTimesCircle,
    faTrash,
    faTruck,
    faUser,
    faUserEdit,
    faUserMinus,
    faUserTimes,
    faWarehouse,
    faWineBottle,
} from "@fortawesome/free-solid-svg-icons";

import {
    FontAwesomeIcon,
    FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

library.add(
    faCode,
    faArrowCircleRight,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faArrowUp,
    faBars,
    faBell,
    faBox,
    faCalendarAlt,
    faCashRegister,
    faCheck,
    faCheckCircle,
    faCircle,
    faClipboard,
    faClone,
    faCog,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faEnvelope,
    faExclamationCircle,
    faExclamationTriangle,
    faEye,
    faFileAlt,
    faFileDownload,
    faFileImport,
    faFingerprint,
    faHistory,
    faInfoCircle,
    faKey,
    faList,
    faLink,
    faLock,
    faMinus,
    faMinusSquare,
    faMoneyBillWaveAlt,
    faPlus,
    faPlusCircle,
    faPlusSquare,
    faPrint,
    faQuestionCircle,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSquare,
    faSync,
    faSyncAlt,
    faTimes,
    faTimesCircle,
    faTrash,
    faTruck,
    faUser,
    faUserEdit,
    faUserMinus,
    faUserTimes,
    faWarehouse,
    faWineBottle,
);

// registra le componenti
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

// July 27, 2019
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Multiselect from "vue-multiselect";
Vue.component("multiselect", Multiselect);
import "vue-multiselect/dist/vue-multiselect.min.css";

// import Title from "../../components/Title.vue";

// https://github.com/mariomka/vue-datetime
import { Settings } from "luxon";
Settings.defaultLocale = "it";

/*
 *********************************************************************************
 * Localizzazione
 *********************************************************************************
 */
import VueI18n from "vue-i18n";
import { messages } from "./translations.js";
Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "it", // set locale
    fallbackLocale: "it",
    messages, // set locale messages
    enableInSFC: true,
    silentTranslationWarn: true,
});

// chiamata nella NavBar
export function setI18nLanguage(lang) {
    i18n.locale = lang;
    axios.defaults.headers.common["Accept-Language"] = lang;
    document.querySelector("html").setAttribute("lang", lang);
    // sessionStorage.setItem("lang", lang);
    // EventBus.$emit("lang", lang);
    return lang;
}

/*
 * axios
 *
 */
import axios from "axios";

/*
 *********************************************************************************
 * Validazione
 *********************************************************************************
 */
// Pass options to make all validators use the italian language, also merge the english and italian attributes with the internal dictionary.
// https://baianat.github.io/vee-validate/guide/localization.html#using-the-dictionary-api
import it from "vee-validate/dist/locale/it";
// import * as VeeValidate from 'vee-validate'
import VeeValidate, { Validator } from "vee-validate";

import { isASCII, greaterThanZero, lessThan } from "./validation.js";

Vue.use(VeeValidate, {
    inject: true,
    fieldsBagName: "veeFields", // https://github.com/bootstrap-vue/bootstrap-vue/blob/dev/docs/markdown/reference/validation/README.md#vee-validate
    // i18nRootKey: 'validations', // customize the root path for validation messages.
    // i18n,
    locale: "it",
    // dictionary: {
    //   // en: { attributes: attributesEn },
    //   it: { messages: messagesIt, attributes: attributesIt }
    // }
});

// Localize takes the locale object as the second argument (optional) and merges it.
Validator.localize("it", it);
// da usare l'internazionalizzazione per questo messaggio...
Validator.extend("isASCII", {
    validate: isASCII,
    getMessage: (field, params, data) => data.message, // + field
});

Validator.extend("greaterThanZero", {
    validate: greaterThanZero,
    getMessage: (field, params, data) => data.message, // + field
});

Validator.extend("lessThan", {
    validate: lessThan,
    getMessage: (field, params, data) => data.message, // + field
});

/*
 *********************************************************************************
 * Filtri template
 * August 04, 2019
 *********************************************************************************
 */
import "./utils/filters.js";
// rende i filter globali, così si possono usare nelle funzion js
Vue.prototype.$filters = Vue.options.filters;

/*
 *********************************************************************************
 * vue custom directive (December 15, 2018)
 *********************************************************************************
 */
Vue.directive("focus", {
    inserted: function(el) {
        el.focus();
    },
});

// August 26, 2019
//import { VuelidatePlugin } from '@vuelidate/core'
//Vue.use(VuelidatePlugin)

// Apr 09, 2021
import { interceptorsSetup } from "./interceptors.js";
interceptorsSetup();

const { isAuthenticated } = useState("loginModule", ["isAuthenticated"]);

/*
 * AUTENTICAZIONE utente e impostazione titolo della pagina non è stato
 * implementata, in Login.vue, la gestione del redirect post login. da
 * finire...
 */
router.beforeResolve((to, from, next) => {
    // imposta il titolo della pagina
    document.title = to.meta.title;
    // vede se la propretà meta "requiresAuth", definita nelle routes, è vera e
    // allora richiede l'autenticazione
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    if (requiresAuth && !isAuthenticated.value) {
        next({
            name: "login",
            params: { nextUrl: to.fullPath },
        });
    } else {
        next();
    }
});

Vue.config.productionTip = false;

// Jan 19, 2021
import "./assets/css/e-sian.css";

import App from "./App.vue";

// https://stackoverflow.com/questions/64396536/vue-cannot-access-webpack-default-export-when-reference-a-components
// c'era circolarità non risolubile con vite invece che webpack (Jul 05, 2021)
// pertanto la componente è stta importata a livello globale
import ElencoAvvisi from "@/components/avviso/ElencoAvvisi.vue";
Vue.component("ElencoAvvisi", ElencoAvvisi);

// app = una "root vue instance" creata dalla funzione "Vue"
export const app = new Vue({
    store, // inietta lo store in ogni componente
    router,
    i18n,
    render: (h) => h(App), // qui si rende la componente App.vue
}).$mount("#app"); // l'elemento in cui montare la app
