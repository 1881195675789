<template>
  <div>
    <b-container class="mt-3 mb-5">
      <h4>{{ $t("storici") }}</h4>

      <p class="font-weight-light">
        {{ currentAzienda != null ? currentAzienda.ragione_sociale : "" }} ({{
          currentAzienda != null ? currentAzienda.cuaa : ""
        }}). Tutti gli stabilimenti.
      </p>

      <p class="lead">{{ $t("testo_storici") }}</p>

      <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
        Formulare una nuova richiesta limitando il numero di dati storici
        richiesti attraverso l'uso dei filtri di ricerca
      </b-alert>

      <b-form-row class="mt-3">
        <b-col sm="12" lg="6">
          <b-form-group
            :label="$t('data_inizio') + ' (data di inizio)'"
            :state="v$.form.data_inizio.$error ? false : null"
            :invalid-feedback="
              v$.form.data_inizio.$error &&
              v$.form.data_inizio.$errors.length > 0
                ? v$.form.data_inizio.$errors[0].$response
                  ? v$.form.data_inizio.$errors[0].$response.data.message
                  : v$.form.data_inizio.$errors[0].$message
                : null
            "
          >
            <b-form-datepicker
              v-model="form.data_inizio"
              locale="it-IT"
              label-help=""
              start-weekday="1"
              hide-header
              value-as-date
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              placeholder="Scegliere una data..."
              :state="v$.form.data_inizio.$error ? false : null"
              :disabled="importazioneStorici || loading.storici || ripristinoArchivi || !isCampagnaAziendaleRiaperta"
            />
            <b-form-text
              >Data da cui iniziare l'importazione degli archivi storici
              (operazioni).</b-form-text
            >
          </b-form-group>
        </b-col>

        <b-col sm="12" lg="6">
          <b-form-group
            :label="$t('data_fine') + ' (data di fine)'"
            :state="v$.form.data_fine.$error ? false : null"
            :invalid-feedback="
              v$.form.data_fine.$error && v$.form.data_fine.$errors.length > 0
                ? v$.form.data_fine.$errors[0].$response
                  ? v$.form.data_fine.$errors[0].$response.data.message
                  : v$.form.data_fine.$errors[0].$message
                : null
            "
          >
            <b-form-datepicker
              v-model="form.data_fine"
              locale="it-IT"
              label-help=""
              start-weekday="1"
              hide-header
              value-as-date
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              placeholder="Scegliere una data..."
              :state="v$.form.data_fine.$error ? false : null"
              :disabled="importazioneStorici || loading.storici || ripristinoArchivi || !isCampagnaAziendaleRiaperta"
            />
            <b-form-text
              >Data in cui terminare l'importazione degli archivi
              storici</b-form-text
            >
          </b-form-group>
        </b-col>
      </b-form-row>

      <hr class="mt-0" />

      <b-form-row>
        <b-col class="text-left">
          <b-button
            @click="onReset"
            variant="light"
            class="mr-2"
            :disabled="loading.storici"
            >Annulla</b-button
          >
          <b-button
            @click="onSubmit"
            :variant="v$.$invalid ? 'danger' : 'primary'"
            :disabled="v$.$invalid || loading.storici || ripristinoArchivi || !isCampagnaAziendaleRiaperta"
            >{{ $t("conferma") }}</b-button
          >
        </b-col>
      </b-form-row>

      <!-- <b-progress v-if="loading.storici" :max="loadingTotal['storici']" class="mt-3" height="1.5rem"> -->
      <!--     <b-progress-bar show-value :value="loadingCurrent.storici">{{ loadingMessage.storici }}</b-progress-bar> -->
      <!-- </b-progress> -->

      <h4 class="mt-5">Rimozione dati storici importati</h4>
      <p class="lead">Rimozione da e-Sian dei dati storici importati</p>
      <b-btn
        @click="handleStorici"
        id="id_storici"
        :disabled="importazioneStorici || loading.storici || ripristinoArchivi"
        variant="primary"
      >
        <template>{{ $t("Rimuovi dati storici") }}</template>
        <!-- <template v-else> -->
        <!--   {{ loadingMessage }} -->
        <!--   <b-spinner small class="ml-1"></b-spinner> -->
        <!-- </template> -->
      </b-btn>

    </b-container>

    <b-modal id="loadingModalStorici" @hide="handleHideStorici" class="text-center" centered hide-header hide-footer>
      <!-- <b-progress :value="current" :max="total"></b-progress> -->
      <b-spinner class="mr-1" small :label="loadingMessage.storici" />{{
        loadingMessage.storici
      }}
    </b-modal>

  </div>
</template>

<style>
.mx-input {
  font-size: 16px;
}
.mx-datepicker {
  width: 100%;
}
</style>


<script>
import axios from "axios";
import { DateTime } from "luxon";
import { mapState, mapMutations } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { ref, onMounted, computed } from "@vue/composition-api";
import { useRouter, useState } from "@u3u/vue-hooks";

import {
  calcolaDataInizioCampagnaPrecedente,
  calcolaDataFineCampagnaPrecedente,
} from "./campagnaUtils.js";
import { showAlert } from "../../utils/eSianUtils.js";
import { strumentiMixin } from "./StrumentiMixins.js";
import { GetCarico } from "../login/getCaricoMixin.js";
import { richiesto } from "@/utils/validators/validators.js";
import {
  controlloDataInizioStorici,
  controlloDataFineStorici,
} from "../../utils/validators/storici.js";
import {
  getProgress as getProgressNew,
  loading,
  loadingCurrent,
  loadingTotal,
  loadingMessage,
} from "@/utils/getProgress.js";
import {_situazioneCurrentStabilimento} from "@/components/strumenti/Strumenti.vue"

// num. max di mesi che è possibile importare
// const LUNGHEZZA = 24;

export default {
  name: "storici",

  mixins: [strumentiMixin, GetCarico],

  props: {
    azienda_id: Number,
    stabilimento_id: Number,
  },

  setup(props, context) {
    const { router } = useRouter();
    const {
      isCampagnaAziendaleRiaperta,
    } = useState("loginModule", [
      "isCampagnaAziendaleRiaperta",
    ]);

    // const situazioneCurrentStabilimento = computed(() => _situazioneCurrentStabilimento())

    const data_inizio = computed(() => calcolaDataInizioCampagnaPrecedente())
    const data_fine = computed(() => calcolaDataFineCampagnaPrecedente())
    
    const form = ref({
      data_inizio: data_inizio.value,
      data_fine: data_fine.value,
    });

    const rules = {
      form: {
        data_inizio: {
          richiesto,
          controlloData: controlloDataInizioStorici(form),
        },
        data_fine: { richiesto, controlloData: controlloDataFineStorici(form) },
      },
    };
    // un "ref" va passato tra "{}"
    const v$ = useVuelidate(rules, { form }, { $autoDirty: true });

    const handleHideStorici = (bvModalEvt) => {
      if (bvModalEvt.trigger == null || bvModalEvt.trigger == "event") {
        return;
      } else {
        bvModalEvt.preventDefault();
        context.root.$bvModal.msgBoxConfirm(
          "L'importazione degli archivi storici continuerà in sottofondo.",
          {
            okVariant: "primary",
            cancelVariant: "light",
            okTitle: "Conferma",
            cancelTitle: "Annulla",
            headerClass: 'p-2 border-bottom-0', 
            footerClass: 'p-2 border-top-0',
            centered: true,
          }).then((value) => {
            if (value) {
              router.push({ name: "home", params: { fromNavBar: true } });
            } else {
              return;
            }
          }).catch((err) => {
            console.log(err);
          });
      }
    };
          
    onMounted(() => {
        if (loading.value.storici) {
          context.root.$bvModal.show("loadingModalStorici");
        }
    })

    return {
      // situazioneCurrentStabilimento,
      isCampagnaAziendaleRiaperta,
      loading,
      loadingMessage,
      loadingCurrent,
      loadingTotal,
      handleHideStorici,
      form,
      v$,
    };
  },

  data() {
    return {
      showDismissibleAlert: false,
      optionsCategoria: [],
    };
  },

  computed: {
    ...mapState("loginModule", [
      "currentAzienda",
      "statoStabilimenti",
      "numeroStabilimenti",
      "carico",
      "optionsCodiceRegistrazione",
    ]),
    ...mapState("strumentiModule", ["storiciTabClicked", "unMountScheduled", "ripristinoArchivi"]),
    ...mapState("operazioneModule", ["optionsSoggetto"]),
  },

  created() {
    this.initOptions();
  },

  methods: {
    ...mapMutations("loginModule", ["setNumeroStoricheAzienda", "setCarico"]),
    ...mapMutations("strumentiModule", [
      "setStoriciTabClicked",
      "scheduleUnMount",
    ]),

    async onSubmit(evt) {

        this.v$.$touch();
        const isFormCorrect = await this.v$.$validate()
        if (!isFormCorrect) return 
        // if (this.v$.form.$error) return;

        this.$bvModal.msgBoxConfirm("Si conferma l'importazione degli archivi storici dal SIAN?", { /*title: "Rimozione archivi storici",*/ okVariant: "primary", cancelVariant: "light", okTitle: "Conferma", cancelTitle: "Annulla", headerClass: 'p-2 border-bottom-0', footerClass: 'p-2 border-top-0', centered: true, })
            .then((value) => {
                if (value == true) {
                    this.mountStorici();
                }
                this.selected = null;
            })
            .catch((err) => {
                console.log(err);
            });
    },

    onReset() {
      this.form = {
        data_inizio: calcolaDataInizioCampagnaPrecedente(),
        data_fine: calcolaDataFineCampagnaPrecedente(),
      };
    },

    async mountStorici() {
      this.setImportazioneStorici(true);
      // apre il modale (lo chiude getProgress)
      this.$bvModal.show("loadingModalStorici");
      this.$set(loadingMessage.value, "storici", "In attesa ricezione dati dal SIAN")
      const data_inizio = DateTime.fromJSDate(
        this.form.data_inizio
      ).toISODate();
      const data_fine = DateTime.fromJSDate(this.form.data_fine).toISODate();
      //  Oct 03, 2019
      const codice_registrazione =
        this.form.codice_registrazione != null &&
        this.form.codice_registrazione != ""
          ? this.form.codice_registrazione.codice
          : null;
      const categoria =
        this.form.categoria != null && this.form.categoria != ""
          ? this.form.categoria.codice
          : null;
      const cliente =
        this.form.cliente != null && this.form.cliente != ""
          ? this.form.cliente.codice
          : null;
      const committente =
        this.form.committente != null && this.form.committente != ""
          ? this.form.committente.codice
          : null;
      const path = `api/mount_storici/${this.azienda_id}`;
      const payload = {
        data_inizio: data_inizio,
        data_fine: data_fine,
        codice_registrazione: codice_registrazione,
        categoria: categoria,
        cliente: cliente,
        committente: committente,
      };

      try {
        const response = await axios.post(path, payload);
        try {
          getProgressNew(
            response.data.task_id,
            this,
            "loadingModalStorici", // id modale
            "storici", // identificativo
            `Importazione archivi storici dell'azienda "${this.currentAzienda.ragione_sociale}" completata.`,
            () => this.setImportazioneStorici(false) // callback
          );
          this.getCarico(); // Apr 01, 2020
        } catch (error) {
          this.setImportazioneStorici(false)
          // se getProgress fallisce (durante il caricamento degli avvisi)...
          showAlert({ error: error }, this);
        }
        // se fallisce mountStorici...
      } catch (error) {
        this.setImportazioneStorici(false)
        showAlert({ error: error }, this);
      }
    },

    handleStorici() {
        this.$bvModal.msgBoxConfirm("SI conferma la rimozione dei dati degli archivi storici importati in precedenza dal SIAN?", { /*title: "Rimozione archivi storici",*/ okVariant: "primary", cancelVariant: "light", okTitle: "Conferma", cancelTitle: "Annulla", headerClass: 'p-2 border-bottom-0', footerClass: 'p-2 border-top-0', centered: true, })
        .then((value) => {
          if (value == true) {
            this.unMount();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    unMount() {
      this.setImportazioneStorici(true);
      // Oct 04, 2019
      var path = `api/unmount_storici/${this.azienda_id}`;
      // Sep 27, 2019
      axios
        .get(path)
        .then((response) => {
          if (response.statusText == "OK") {
            this.setCarico(response.data.carico);
            // this.loading = false;
            showAlert(
              {
                message:
                  "Rimozione dei dati importati dagli archivi storici completata.",
                variant: "success",
              },
              this
            );
          }
        })
        .catch((error) => {
          showAlert({ error: error }, this);
        })
        .finally(() => this.setImportazioneStorici(false));
    },

    initOptions() {
      if (sessionStorage.getItem("optionsCategoria")) {
        try {
          this.optionsCategoria = JSON.parse(
            sessionStorage.getItem("optionsCategoria")
          );
        } catch (e) {
          sessionStorage.removeItem("optionsCategoria");
        }
      }
    },

    customCategoriaLabel({ descrizione, codice, unita }) {
      return `${descrizione} - ${unita} (${codice})`;
    },
  },

  beforeDestroy() {
    this.setImportazioneStorici(false);
  },
};
</script>

<i18n>
{
  "en": {
  },
  "it": {
    "categoria": "Categoria prodotto",
    "categoria_placeholder": "Immettere la categoria del prodotto...",
    "codice_registrazione": "Codice registrazione",
    "codice_registrazione_placeholder": "Immettere il codice registrazione...",
    "cliente": "Cliente/Fornitore",
    "cliente_placeholder": "Selezionare il cliente o fornitore...",
    "committente": "Committente",
    "committente_placeholder": "Selezionare il committente...",
    "data_fine": "Alla data",
    "data_inizio": "Dalla data",
    "conferma": "Importa archivi storici",
    "storici": "Importazione archivi storici",
    "testo_storici": "Importazione delle operazioni dagli archivi storici del SIAN per la loro successiva interrogazione. Gli archivi storici vengono importati per tutti gli stabilimenti aziendali."
  }
}
</i18n>
